<template>
  <Form :validation-schema="schema" @submit="handleSignup" ref="signUpModalRef" v-slot="{ errors }">
    <div
      class="modal fade"
      id="signUpModal"
      tabindex="-1"
      aria-labelledby="signUpModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="heading-06 text-neutral-07 text-center mb-32px">Înregistrare</div>
          <div class="modal-body">
            <div class="mb-3" :class="{ 'invalid-input': errors.name_reg }">
              <label for="name_reg" class="text-neutral-07 text-button-02 mb-2">Nume complet</label>
              <Field
                v-model="user.Name"
                type="email"
                name="name_reg"
                id="name_reg"
                placeholder="Nume complet"
                class="form-control"
              />
              <ErrorMessage name="name_reg" class="error-message" />
            </div>
            <div class="mb-3" :class="{ 'invalid-input': errors.email_reg }">
              <label for="email_reg" class="text-neutral-07 text-button-02 mb-2"
                >Adresa de email</label
              >
              <Field
                v-model="user.Email"
                type="email"
                name="email_reg"
                id="email_reg"
                placeholder="Email"
                class="form-control"
              />
              <ErrorMessage name="email_reg" class="error-message" />
            </div>
            <div class="mb-3" :class="{ 'invalid-input': errors.password_reg }">
              <label for="password_reg" class="text-neutral-07 text-button-02 mb-2">Parola</label>
              <div class="input-group right">
                <Field
                  v-model="user.Password"
                  :type="[showPassword ? 'text' : 'password']"
                  name="password_reg"
                  id="password_reg"
                  placeholder="Parola"
                  class="form-control"
                  autocomplete="on"
                />
                <span
                  class="input-group-text"
                  @pointerdown="showPassword = true"
                  @pointerup="showPassword = false"
                >
                  <img src="../../assets/images/icons/eye-linear-20x20.svg"  v-if="showPassword"/>
                  <img src="../../assets/images/icons/eye-slash-linear-20x20.svg" v-else/>
                </span>
              </div>
              <ErrorMessage name="password_reg" class="error-message" />
            </div>

            <div class="mb-3" :class="{ 'invalid-input': errors.confirm_password_reg }">
              <label for="confirm_password_reg" class="text-neutral-07 text-button-02 mb-2"
                >Confirmare parola</label
              >
              <div class="input-group right">
                <Field
                  v-model="user.ConfirmPassword"
                  :type="[showConfirmedPassword ? 'text' : 'password']"
                  name="confirm_password_reg"
                  id="confirm_password_reg"
                  placeholder="Parola"
                  class="form-control"
                  autocomplete="on"
                />
                <span
                  class="input-group-text"
                  @pointerdown="showConfirmedPassword = true"
                  @pointerup="showConfirmedPassword = false"
                >
                 <img src="../../assets/images/icons/eye-linear-20x20.svg"  v-if="showConfirmedPassword"/>
                  <img src="../../assets/images/icons/eye-slash-linear-20x20.svg" v-else/>
                </span>
              </div>
              <ErrorMessage name="confirm_password_reg" class="error-message" />
            </div>
            <div class="mb-3 paragraph-03">
              <span class="text-neutral-05">
                Datele dvs. personale vor fi folosite pentru a vă procesa comanda, pentru a vă
                susține experiența pe tot acest site web și pentru alte scopuri descrise în pagina
                noastră
              </span>
              <router-link :to="{ name: 'TermsAndConditions' }" @click="CloseModal()">
                politică de confidențialitate.
              </router-link>
            </div>
            <!-- <div class="mb-3">
              <div class="form-check d-inline-block">
                <Field
                  v-model="user.TermsAndConfitions"
                  class="form-check-input"
                  type="checkbox"
                  value="true"
                  id="acceptTerms"
                  name="acceptTerms"
                />
                <label
                  class="text-button-02 ms-2 text-neutral0-07 pointer"
                  for="acceptTerms"
                  style="line-height: 2 !important"
                >
                  Sunt de acord cu Termenii și Condițiile site-ului!
                </label>
              </div>
              <ErrorMessage name="acceptTerms" class="error-message d-block" />
            </div> -->
            <button class="button btn-medium-wider btn-primary-solid d-block w-100 mt-3">
              Creează contul
            </button>
            <div class="error-message mt-2 pt-1 text-center" v-if="errorMessage">
              {{ errorMessage }}
            </div>
            <div class="row text-button-02 text-neutral-06 mt-4 mb-2">
              <div class="col pointer" @click="$emit('passwordRecovery', 'signUpModal')">
                Am uitat parola
              </div>
              <div class="col-auto pointer" @click="$emit('logIn', 'signUpModal')">
                Autentificare
              </div>
            </div>
          </div>
          <!-- <hr />
          <div class="mt-3">
            <p class="text-button-02 text-neutral-06">Alte optiuni de conectare:</p>
            <div class="row other-option-btn">
              <div class="col-4">
                <button class="button btn-neutral-outlined" type="button">
                  <img src="../../assets/images/icons/facebook-20x20.svg" class="me-2" alt="..."/> Facebook
                </button>
              </div>
              <div class="col-4">
                <button class="button btn-neutral-outlined" type="button">
                  <img src="../../assets/images/icons/gmail-20x20.svg" class="me-2" alt="..."/> Gmail
                </button>
              </div>
              <div class="col-4">
                <button class="button btn-neutral-outlined" type="button">
                  <img src="../../assets/images/icons/microsoft-20x20.svg" class="me-2" alt="..."/> Microsoft
                </button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Field, ErrorMessage, Form } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'SignUpModal',

  data() {
    return {
      user: {
        TermsAndConfitions: false,
      },
      showPassword: false,
      showConfirmedPassword: false,
      errorMessage: '',
    };
  },
  components: {
    Field,
    ErrorMessage,
    Form,
  },
  methods: {
    CloseModal() {
      // eslint-disable-next-line no-undef
      $('#signUpModal').modal('hide');
    },
    handleSignup() {
      this.user.UserName = `${this.user.FirstName} ${this.user.LastName}`;
      this.$store.state.loader = true;
      this.errorMessage = '';
      this.successful = false;
      this.$store
        .dispatch('auth/register', this.user)
        .then(() => {
          this.CloseModal();
          this.$store.state.loader = false;
          this.$utils.ToastNotify(
            'success',
            'Validează contul apăsând pe link-ul de confirmare trimis la adresa ta de email.',
          );
        })
        .catch((error) => {
          this.$store.state.loader = false;
          if (error.response.data.status !== 409) this.errorMessage = 'Nu s-a putut realiza înregistrarea.';
          else this.errorMessage = 'Acest e-mail a fost utilizat deja. Încearcă din nou cu un alt e-mail.';
        });
    },
    ClearModal() {
      this.showPassword = false;
      this.showConfirmedPassword = false;
      this.errorMessage = '';
      this.$refs.signUpModalRef.resetForm();
    },
  },

  computed: {
    schema() {
      return yup.object({
        name_reg: yup
          .string()
          .required('Numele este obligatoriu,')
          .test('verify-name', 'Va rugam introduceti numele complet', (name) => {
            const verifyName = name.split(' ');
            if (verifyName.length < 2) {
              return false;
            }
            return true;
          }),
        email_reg: yup
          .string()
          .email('Email-ul nu este valid.')
          .required('Email-ul este obligatoriu.'),
        password_reg: yup
          .string()
          .min(3)
          .required('Parola este obligatorie.')
          .test(
            'password-complecity',
            'Parola trebuie să conțină minimum o majusculă, un caracter special și o cifră.',
            (password) => {
              const specialCharRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&_]).{8,}$/;
              const numberRegex = /[0-9]/;
              const uppercaseRegex = /[A-Z]/;
              return (
                specialCharRegex.test(password) &&
                numberRegex.test(password) &&
                uppercaseRegex.test(password)
              );
              // eslint-disable-next-line comma-dangle
            }
          ),
        confirm_password_reg: yup
          .string()
          .required('Confirmarea parolei este obligatorie.')
          .test(
            'check-password',
            'Parolele nu se potrivesc.',
            // eslint-disable-next-line comma-dangle
            (confirmPassword) => confirmPassword === this.user.Password
          ),
        // acceptTerms: yup
        //   .boolean()
        //   .required()
        //   .test('accept-terms', 'Va rugam acceptati termeni si conditiile!', (terms) => terms),
      });
    },
  },
};
</script>
<style scoped>
a {
  font-weight: 600;
  color: var(--secondary-5);
}

hr {
  border-color: var(--blacktint-2);
}

.other-option-btn .btn-neutral-outlined {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--neutral-2);
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: var(--neutral-6);
  display: flex;
  align-items: center;
  width: 100%;
}
</style>
