<script>
import googleLoginHandler from '../../utils/google';

export default {
  props: ['test'],
  mounted() {
    googleLoginHandler(window);
  },
};
</script>

<template>
  <button id="google" class="button btn-neutral-outlined justify-content-center" type="button">
    <img src="../../assets/images/icons/gmail-20x20.svg" class="me-2" alt="..." />
    Gmail
  </button>
</template>
