<template>
  <!-- start from lg-->
  <Navbar @logIn="logIn" @logOut="logOut" @signUp="signUp" v-if="deviceSize === 'xl'" />
  <!-- start from sm-->
  <tablet-navbar @logIn="logIn" @signUp="signUp" v-if="deviceSize === 'sm'" />
  <!--mobile-->
  <mobile-navbar
    @logIn="logIn"
    @signUp="signUp"
    v-if="$route.name != 'UserProfile' && deviceSize === 'mobile'"
  />
  <router-view />
  <Footer :class="{ 'd-none d-sm-flex': $route.name === 'UserProfile' }" />
  <LogInModal ref="logInRef" @passwordRecovery="passwordRecovery" @signUp="signUp" />
  <SignUpModal ref="signUpRef" @passwordRecovery="passwordRecovery" @logIn="logIn" />
  <PasswordRecoveryModal ref="passwordRecoveryRef" />
</template>

<script>
import Navbar from '../../components/General/Navbar.vue';
import Footer from '../../components/General/Footer.vue';
import LogInModal from '../../components/Auth/LogInModal.vue';
import SignUpModal from '../../components/Auth/SignUpModal.vue';
import PasswordRecoveryModal from '../../components/Auth/PasswordRecoveryModal.vue';
import TabletNavbar from '../../components/General/TabletNavbar.vue';
import MobileNavbar from '../../components/General/LayoutMobileNavbarComponent.vue';

export default {
  name: 'Layout',
  components: {
    Navbar,
    Footer,
    LogInModal,
    SignUpModal,
    PasswordRecoveryModal,
    TabletNavbar,
    MobileNavbar,
  },
  data() {
    return { deviceSize: null };
  },
  methods: {
    passwordRecovery(closeModal) {
      this.$refs.passwordRecoveryRef.ClearModal();
      // eslint-disable-next-line no-undef
      $(`#${closeModal}`).modal('hide');
      // eslint-disable-next-line no-undef
      $('#passwordRecoveryModal').modal('show');
    },
    logIn(closeModal) {
      // eslint-disable-next-line no-undef
      if (closeModal) {
        // eslint-disable-next-line no-undef
        $(`#${closeModal}`).modal('hide');
      }
      this.$refs.logInRef.ClearModal();
      // eslint-disable-next-line no-undef
      $('#logInModal').modal('show');
    },
    signUp(closeModal) {
      if (closeModal) {
        // eslint-disable-next-line no-undef
        $(`#${closeModal}`).modal('hide');
      }
      this.$refs.signUpRef.ClearModal();
      // eslint-disable-next-line no-undef
      $('#signUpModal').modal('show');
    },
    logOut() {
      this.$store.dispatch('auth/logout').then(() => {
        const routeData = this.$router.resolve({ name: 'HomePage' });
        window.open(routeData.href, '_self');
      });
    },
    updateDeviceSize() {
      const width = window.innerWidth;
      switch (true) {
        case width >= 1200:
          this.deviceSize = 'xl';
          break;
        case width >= 576:
          this.deviceSize = 'sm';
          break;
        default:
          this.deviceSize = 'mobile';
          break;
      }
    },
  },
  mounted() {
    this.updateDeviceSize();
    window.addEventListener('resize', this.updateDeviceSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateDeviceSize);
  },
};
</script>
