/* eslint-disable */
import axios from 'axios';
import localStorageMethods from './localStorage';
import store from '../store/index';
import utils from './utils';

function googleLoginHandler(window) {
  setGoogleAccounts(window);
  const button = getGoogleButton(window.document);
  addButtonBehavior(button);
}

function setGoogleAccounts(window) {
  window.onload = function () {
    google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_APP_ID,
      callback: handleGoogleUser,
    });
  };
}

function handleGoogleUser(user) {
  const token = user.credential;
  const searchParams = {
    Token: token,
    ...(store.state.locationId ? { LocationId: store.state.locationId } : ''),
    ...(localStorageMethods.GetCartId() ? { CartId: localStorageMethods.GetCartId() } : ''),
  };
  axios
    .put(`${process.env.VUE_APP_BASE_URL}/Auth/login-google?${new URLSearchParams(searchParams)}`, [])
    .then((response) => {
      if (response.data.Token) {
        localStorageMethods.SetCartId('');
        localStorage.setItem('user', JSON.stringify(response.data));
        window.location.reload();
      }
    })
    .catch(() => {
      utils.ToastNotify('error', 'Something went wrong');
    });
}

function getGoogleButton(document) {
  return document.getElementById('google');
}

function addButtonBehavior(button) {
  button.onclick = function () {
    deleteCookie('g_state');
    google.accounts.id.prompt();
  };
}

function deleteCookie(name) {
  const expiration = '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = name + expiration;
}

export default googleLoginHandler;
