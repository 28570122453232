<template>
  <div class="nav-bar d-none d-xl-flex">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <router-link :to="{ name: 'HomePage' }">
          <img src="/logo-delicitate.webp" alt="..." width="235" height="52" />
        </router-link>
      </div>
      <div class="col">
        <ul class="menu-pages justify-content-center">
          <li>
            <router-link :to="{ name: 'HomePage' }" exact>Acasă</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Products' }" exact>Produse</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Blogs' }" exact>Articole</router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'HomePage' }" exact>Gift Card</router-link>
          </li> -->
          <li>
            <router-link :to="{ name: 'Contact' }" exact>Contact</router-link>
          </li>
        </ul>
      </div>
      <div class="col-auto">
        <ul class="menu-btn-actions float-end">
          <!-- <li>
            <button type="button" @click="$emit('logIn')" class="page">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5 29C24.7467 29 29 24.7467 29 19.5C29 14.2533 24.7467 10 19.5 10C14.2533 10 10 14.2533 10 19.5C10 24.7467 14.2533 29 19.5 29Z"
                  stroke="#688088"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30 30L28 28"
                  stroke="#688088"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </li> -->
          <li v-if="!this.$store.state.auth.user" class="d-flex align-items-center">
            <span @click="$emit('logIn')" class="text-button-02 text-neutral-05 pointer">
              Autentificare
            </span>
          </li>
          <li v-else>
            <button
              type="button"
              class="btn p-0"
              id="dropdownUser"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="../../assets/images/icons/profile-circle-linear-40x40.svg" alt="..." />
              <span>
                {{ this.$store.state.auth.user.FirstName }}
                {{ this.$store.state.auth.user.LastName }}
              </span>
            </button>

            <ul class="dropdown-menu" aria-labelledby="dropdownUser">
              <li class="w-100">
                <router-link :to="{ name: 'UserProfile' }" class="dropdown-item">
                  Profilul utilizatorului
                </router-link>
              </li>
              <li>
                <button type="button" class="dropdown-item" @click="$emit('logOut')">
                  Deconectează-te
                </button>
              </li>
            </ul>
          </li>
          <li>
            <router-link
              :to="{ name: 'Cart' }"
              class="page position-relative"
              aria-label="Cosul tau de cumparaturi"
            >
              <dot-products-count-component top="2px" right="1px" />
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 10H11.74C12.82 10 13.67 10.93 13.58 12L12.75 21.96C12.61 23.59 13.9 24.99 15.54 24.99H26.19C27.63 24.99 28.89 23.81 29 22.38L29.54 14.88C29.66 13.22 28.4 11.87 26.73 11.87H13.82"
                  stroke="#688088"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.25 30C24.9404 30 25.5 29.4404 25.5 28.75C25.5 28.0596 24.9404 27.5 24.25 27.5C23.5596 27.5 23 28.0596 23 28.75C23 29.4404 23.5596 30 24.25 30Z"
                  stroke="#688088"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.25 30C16.9404 30 17.5 29.4404 17.5 28.75C17.5 28.0596 16.9404 27.5 16.25 27.5C15.5596 27.5 15 28.0596 15 28.75C15 29.4404 15.5596 30 16.25 30Z"
                  stroke="#688088"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 16H29"
                  stroke="#688088"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect x="24" y="8" width="8" height="8" rx="4" fill="#D6366C" />
              </svg>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DotProductsCountComponent from '../Cart/DotProductsCountComponent.vue';

export default {
  name: 'Navbar',
  emits: ['logIn', 'logOut', 'signUp'],
  components: { DotProductsCountComponent },
};
</script>

<style scoped>
.nav-bar {
  border: 1px solid transparent;
  border-width: 0px 0px 1px 0px;
  border-image: linear-gradient(
    99.35deg,
    rgba(191, 165, 119, 0.7) 0%,
    rgba(223, 210, 187, 0.7) 8.31%,
    rgba(149, 105, 29, 0.7) 14.58%,
    rgba(223, 210, 187, 0.7) 40.1%,
    rgba(191, 165, 119, 0.7) 66.84%,
    rgba(244, 240, 232, 0.7) 89.12%,
    rgba(149, 105, 29, 0.7) 100%
  );
  border-image-slice: 1;
  height: 84px;
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 59px;
  padding-right: 59px;
  z-index: 7;
}

.menu-pages li a:hover {
  color: var(--neutral-7);
}

ul {
  margin-bottom: 0px;
}
.btn:active {
  outline: 0 !important;
  border: 1px solid transparent !important;
}
.btn.show {
  outline: 0 !important;
  border: 1px solid transparent !important;
}
.menu-pages li a {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: var(--neutral-7);
  transition: 0.2s;
}
.menu-pages li:not(:last-child) {
  margin-right: 64px;
}
.menu-btn-actions a.page:hover {
  border: 1px solid var(--neutral-3);
}
.menu-btn-actions a.page {
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 50%;
  display: block;
  transition: 0.2s;
}
.menu-btn-actions button.page:hover {
  border: 1px solid var(--neutral-3);
}
.menu-btn-actions button.page {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
  transition: 0.2s;
}

.menu-btn-actions li:not(:last-child) {
  margin-right: 40px;
}

.menu-pages,
.menu-btn-actions {
  list-style-type: none;
  display: flex;
  padding-left: 0;
}

.router-link-exact-active {
  color: var(--primary-5) !important;
}
</style>
