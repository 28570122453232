/* eslint-disable no-undef */
import axios from 'axios';
import localStorageMethods from './localStorage';
import store from '../store/index';

async function apiAuthenticate(userId, accessToken) {
  const locId = store.state.locationId ? store.state.locationId : -1;
  await axios
    .post(
      `/api/Auth/login-facebook/${accessToken}/${userId}/${locId}/${localStorageMethods.GetCartId()}`,
      [],
    )
    .then((response) => {
      if (response.data.Token) {
        localStorageMethods.SetCartId('');
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      window.location.reload();
    })
    .catch(() => {});
}

async function login() {
  const { authResponse } = await new Promise(FB.login, { scope: 'email' });
  if (!authResponse) return;
  await apiAuthenticate(authResponse.userID, authResponse.accessToken);
}

export default {
  login,
  apiAuthenticate,
  // logout,
};
